import { remove } from 'lodash';
import {
  CityTaxConfiguration,
  CityTaxConfigurationFieldsFormValue,
  CityTaxOptionsFieldsFormValue,
} from '../../../models';
import { PRICE_FIELDS } from '../../../features/commons/city-tax-configurations-details/constants/price-field-map';

function reduceFieldsValueToFieldsValueWithPriceTypedValues(
  values: CityTaxOptionsFieldsFormValue[],
): CityTaxOptionsFieldsFormValue[];
function reduceFieldsValueToFieldsValueWithPriceTypedValues(
  values: CityTaxConfigurationFieldsFormValue[],
): CityTaxConfigurationFieldsFormValue[];
function reduceFieldsValueToFieldsValueWithPriceTypedValues(
  values:
    | CityTaxConfigurationFieldsFormValue[]
    | CityTaxOptionsFieldsFormValue[],
): CityTaxConfigurationFieldsFormValue[] | CityTaxOptionsFieldsFormValue[] {
  return values?.map(
    (
      rule: CityTaxConfigurationFieldsFormValue | CityTaxOptionsFieldsFormValue,
    ) => {
      const { fields } = rule;

      let newFields = [...(fields || [])];

      const priceFieldsValues = remove(
        newFields,
        ({ name }) => PRICE_FIELDS[name],
      );

      const priceTypedFieldValue = priceFieldsValues.reduce(
        (acc, curr) => {
          const { name, value } = curr;

          const priceTypeValue = priceFieldsValues?.find(
            ({ name }) => name === 'price_type',
          )?.value;

          const amountPriceValue = priceFieldsValues?.find(
            ({ name }) => name === 'amount_price',
          )?.value;

          const percentagePriceValue = priceFieldsValues?.find(
            ({ name }) => name === 'percentage_price',
          )?.value;

          const price =
            priceTypeValue === 'amount'
              ? amountPriceValue
              : percentagePriceValue;

          acc = {
            ...acc,
            value: {
              ...acc.value,
              price,
            },
          };

          if (name === 'price_type') {
            acc = {
              ...acc,
              value: {
                ...acc.value,
                price_type: value as string,
              },
            };
          }

          return acc;
        },
        {
          name: 'price_typed',
          value: {
            price_type: 'amount',
            price: null,
          },
        },
      );

      if (priceFieldsValues.length) {
        newFields = [...newFields, priceTypedFieldValue];
      }

      return {
        ...rule,
        fields: newFields,
      } as any;
    },
  );
}

export const parseConfigurationApiValueToConfigurationWithPriceTyped = (
  configurations: CityTaxConfiguration,
): CityTaxConfiguration => {
  const { configuration_fields, configuration_option_fields, overrides } =
    configurations;

  let configurationParsed = {
    ...configurations,
    configuration_fields:
      reduceFieldsValueToFieldsValueWithPriceTypedValues(configuration_fields),
    configuration_option_fields:
      reduceFieldsValueToFieldsValueWithPriceTypedValues(
        configuration_option_fields,
      ),
  };

  if (overrides?.length) {
    configurationParsed = {
      ...configurationParsed,
      overrides: overrides.map(
        parseConfigurationApiValueToConfigurationWithPriceTyped,
      ),
    };
  }
  return configurationParsed;
};
